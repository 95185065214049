@import "themes/scss/variables";

.modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    position: relative;
    background-color: white;
    border-radius: 3px;
    max-width: 100%;
    overflow: auto;
    max-height: 100vh;
  }

  .close {
    position: absolute;
    right: 30px;
    top: 30px;
    width: 32px;
    height: 32px;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: #cccccc;
      border-radius: 25px;
      svg {
        path {
          fill: $light_grey !important;
        }
      }
    }
  }
}

.forgot-password {
  .content {
    display: flex;
    justify-content: center;
    width: 500px;
    height: 320px;
    background: #ffffff;
    border-radius: 3px;
  }
  .forgot-password-body {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .forgot-password-label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #000000;
      margin: 59.43px 51.45px 0 51.45px;
    }
    .buttons-wrapper {
      margin-top: 65px;
      .ok-forgot {
        margin-top: 19px;
      }
      .cancel-forgot {
        width: 112px;
        height: 46px;
        box-sizing: border-box;
        border-radius: 5px;
        border: 1px solid #3399ff;
        color: #3399ff;
        background: #ffffff;
        margin-right: 20px;
        p {
          font-style: normal;
          font-weight: normal;
          font-size: 17px;
          line-height: 20px;
          color: #3399ff;
          margin: 0;
        }
        &:hover {
          background: #f2f9ff;
        }
        &:active,
        &:focus {
          background: #dcedfe;
          border: 1px solid #3399ff;
        }
        &:disabled {
          background: #dedede;
          border: 1px solid #dedede;
        }
      }
    }
    .ok-forgot {
      width: 112px;
      height: 46px;
      background: #3399ff;
      color: #ffffff;
      border-radius: 5px;
      &:hover {
        background: #0b85ff;
      }
      &:active {
        background: #006edc;
      }
      &:disabled {
        background: #add6ff;
      }
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 20px;
        color: #ffffff;
        margin: 0;
      }
      button {
        margin-top: 36px !important;
      }
    }
    .input-wrapper {
      height: 28px;
      width: 300px;
      margin-top: 10px;
      background-color: $white;
      border-bottom: 2px solid #a9abae;
      &:focus-within {
        border-bottom: 2px solid #000000;
      }
      input {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #000000;
      }
      .invalid-email {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 24px;
        color: #ef3d23;
        margin-top: 5px;
      }
    }

    .form-sent {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #000000;
      }
      p:first-child {
        margin-top: 104px;
      }
      .email-p {
        color: #3399ff;
      }
      .ok-forgot {
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 22px;
        color: #ffffff;
        margin-top: 36px;
      }
    }
  }

  .close {
    top: 21px;
    right: 21px;
    position: absolute;
    width: 32px;
    height: 32px;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: #cccccc;
      border-radius: 25px;
      svg {
        path {
          fill: $light_grey !important;
        }
      }
    }
  }
}

.change-password {
  .content {
    width: 447px;
    height: 521px;
    background: #ffffff;
    border-radius: 3px;
  }
  .change-password-body {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
      margin-bottom: 46px;
      margin-top: 50px;
    }
    form {
      width: 100%;
      height: 85%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;
      div {
        width: 100%;
      }
      .inputs-wrapper {
        height: inherit;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        span:first-child {
          width: 340px !important;
          height: 61px !important;
          margin-bottom: 39px !important;
        }
        span:nth-child(2) {
          margin-bottom: 40px;
        }
        span:nth-child(3) {
          margin-bottom: 42px;
        }
      }
      .buttons-wrapper {
        display: flex;
        justify-content: center;
        margin-bottom: 57px;
        button {
          width: 112px;
          height: 46px;
          border-radius: 5px;
          font-style: normal;
          font-weight: normal;
          font-size: 17px;
          line-height: 20px;
        }
        .submit-btn {
          background: #3399ff;
          color: #ffffff;
          &:hover {
            background: #0b85ff;
          }
          &:active,
          &:focus {
            background: #006edc;
          }
          &:disabled {
            background: #add6ff;
          }
        }
      }
      span {
        max-width: 340px;
        width: 100%;
        height: 70px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &:focus-within {
          label {
            color: #000000;
          }
          svg {
            path {
              fill: #000000;
            }
          }
        }
        label {
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          color: #6e6e6e;
        }
        input {
          height: 40px;
          border: none;
          border-bottom: 2px solid #6e6e6e;
          &:active,
          &:focus {
            border-bottom: 2px solid #000000;
            outline: none !important;
          }
        }
        input:focus + label {
          color: red;
        }
        svg {
          position: absolute;
          right: 0;
          bottom: 10px;
          path {
            fill: #6e6e6e;
          }
        }
      }
    }
  }

  .close {
    top: 21px;
    right: 21px;
    position: absolute;
    width: 32px;
    height: 32px;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: #cccccc;
      border-radius: 25px;
      svg {
        path {
          fill: $light_grey !important;
        }
      }
    }
  }
}
