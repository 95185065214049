.sessionTime {
  display: flex;
  align-items: center;

  input {
    text-align: center;
    width: 72px;
    height: 32px;
    position: relative;
    z-index: 1;
    font-size: 12px;
    background-color: white;
    border-radius: 3px;
    border: 1px solid #dedede;
    outline: none;
    color: black;

    &[name="start"] {
      margin-right: 7px;
    }
  }

  input[type="time"]::-webkit-calendar-picker-indicator {
    min-width: 100%;
    position: absolute;
    right: 0;
    z-index: 0;
    background: transparent;
  }
}
