.eventName {
  .event-name-input {
    width: 100%;
    border: none;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    padding: 10px 0;
  }
}
