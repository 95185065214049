@import "themes/scss/variables";

$month_width: 347px;

.rangeDate {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  // position: relative;
}

.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  .DayPicker-CustomDay {
    background-color: $grey_very_light !important;
    color: $black;
  }
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: $grey_very_light !important;
}

.DayPicker-Day--today {
  color: $blue_primary_2 !important;
}

.DayPicker-navBar {
  .previous,
  .next {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }

  .previous {
    left: -20px;
  }

  .next {
    right: -20px;
  }
}

.DayPicker-Months {
  .DayPicker-Month {
    width: $month_width;
    margin: 0 23px;
  }
}

.DayPicker-Caption {
  font-weight: bold;
  font-size: 16px;
}

.DayPicker-Weekdays {
  display: flex !important;

  .DayPicker-Weekday {
    width: calc(#{$month_width} / 7);
    font-weight: bold;
    font-size: 16px;
  }
}

.DayPicker-Week {
  display: flex !important;
  margin-top: 15px;
  border-radius: 93px;
  overflow: hidden;
}

.DayPicker-Day--selected {
  &.DayPicker-Day--excluded {
    .DayPicker-CustomDay::after {
      content: "";
      display: block;
      width: 90%;
      height: 1px;
      position: absolute;
      background-color: $black;
      top: calc(100% / 2);
      transform: rotate(-39.81deg);
    }
    .DayPicker-CustomDay {
      color: $grey_normal !important;
    }
  }
}

.DayPicker-Day {
  width: calc(#{$month_width} / 7);
  height: calc(#{$month_width} / 7);
  padding: 0 !important;

  &:hover {
    border-radius: 50%;
  }

  .DayPicker-CustomDay {
    width: 100%;
    height: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;

    &.isLast {
      border-bottom-right-radius: 50% !important;
      border-top-right-radius: 50% !important;
    }

    &.isFirst {
      border-bottom-left-radius: 50% !important;
      border-top-left-radius: 50% !important;
    }
  }
}

.Selectable {
  .DayPicker-Day {
    .DayPicker-CustomDay {
    }
  }
}
.Selectable {
  .DayPicker-Day--start {
    border-radius: 0;
    border-bottom-left-radius: 50% !important;
    border-top-left-radius: 50% !important;

    .DayPicker-CustomDay {
      border-radius: 50% !important;
      background-color: $black;
      color: $white;
    }
  }
}

.Selectable {
  .DayPicker-Day--end {
    border-radius: 0;
    border-bottom-right-radius: 50% !important;
    border-top-right-radius: 50% !important;

    .DayPicker-CustomDay {
      border-radius: 50% !important;
      background-color: $black;

      color: $white;
    }
  }
}

.DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--start .DayPicker-CustomDay:after,
.DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--end .DayPicker-CustomDay:after {
  background-color: #ffffff;
}
