.empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);

  &-content {
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-message {
    margin-left: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #3399ff;
  }
}

@media screen and (max-width: 1440px) {
  .empty {
    &-content {
    }
    &-message {
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .empty {
    &-content {
    }
    &-message {
      font-size: 18px;
    }
  }
}
