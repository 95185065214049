@import "themes/scss/variables";

.frequencyCell {
  max-width: 81px;
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border-radius: 3px;
}
