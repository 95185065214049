@import "themes/scss/variables";

.header {
  transition: 1s all;

  &.header-toggle-hide {
    overflow: hidden;
    transition: 1s all;
  }

  img {
    display: block;
    margin-right: 25px;
  }

  &-top-block {
    height: 90px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: 1px solid $grey_medium;
  }

  &-top-right-block {
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    .club-logo-content {
      width: 136px;
      margin-right: 25px;

      img {
        width: 100%;
        max-height: 91px;
        margin-right: 0;
      }
    }

    button {
      margin-right: 10px;
      font-size: 13px;
    }

    .settingsDropDown {
      .content {
        width: max-content;
        padding: 0;
        margin-top: 21px;
        left: unset;
        right: calc(100% - 15px);
        border: 0.5px solid #3399ff;
        border-radius: 5px;

        .settings-frop-down-item {
          width: 136px;
          height: 31px;
          margin: 0;
          padding: 7px 10px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          box-sizing: border-box;
          text-decoration: none;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;

          span {
            display: flex;
            align-items: center;
            justify-content: center;

            margin-right: 9px;
            color: #3399ff;
          }

          &.settings-frop-down-item span {
            background-repeat: no-repeat;
            width: 17px;
            height: 17px;
          }

          &.settings span {
            background-image: url(~assets/svg/settings-blue-icon.svg);
          }

          &.log-out span {
            background-image: url(~assets/svg/log-out-blue-icon.svg);
          }

          &:hover {
            color: white;
            background-color: #3399ff;

            &.settings span {
              background-image: url(~assets/svg/settings-white-icon.svg);
            }

            &.log-out span {
              background-image: url(~assets/svg/log-out-icon.svg);
            }
          }
        }
      }
    }
  }

  &-bottom-block {
    padding: 48px 0 0 0;
    border-bottom: 2px solid $grey_medium;
  }

  &-nav {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 17px;
    a {
      width: 160px;
      height: 54px;
      line-height: 54px;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: $blue_primary;
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      &:hover {
        background-color: #f2f9ff;
      }
      &:focus {
        color: #ffffff;
        background-color: #3399ff;
      }
      &:hover:focus {
        background-color: #0b85ff;
      }
      &:active {
        color: #3399ff;
        background-color: #dcedfe;
      }
      &:first-child {
        display: flex;
        border: 1px solid #3399ff;
        border-radius: 5px 0 0 5px;
      }
      &:last-child {
        border: 1px solid #3399ff;
        border-radius: 0 5px 5px 0;
      }

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;

        &.icon {
          width: 15px;
          height: 15px;
        }
      }

      &:nth-child(2) {
        border-top: 1px solid #3399ff;
        border-bottom: 1px solid #3399ff;
      }

      &.active {
        color: $white;
        background-color: $blue_primary;
      }

      &.new {
        span {
          background-image: url(~assets/svg/cross-icon.svg);
        }
        &.active {
          span {
            background-image: url(~assets/svg/cross-icon-white.svg);
          }
        }
      }

      // &.dashboard {
      //   span {
      //     background-image: url(~assets/svg/dashboard-icon-inactive.svg);
      //   }
      //   &.active {
      //     span {
      //       background-image: url(~assets/svg/dashboard-icon-active.svg);
      //     }
      //   }
      // }

      // &.audience {
      //   span {
      //     background-image: url(~assets/svg/audience-icon-inactive.svg);
      //   }
      //   &.active {
      //     span {
      //       background-image: url(~assets/svg/audience-icon-active.svg);
      //     }
      //   }
      // }

      // &.settings {
      //   span {
      //     background-image: url(~assets/svg/settings-icon-inactive.svg);
      //   }
      //   &.active {
      //     span {
      //       background-image: url(~assets/svg/settings-icon-active.svg);
      //     }
      //   }
      // }
    }
  }
}

@media screen and (max-width: 1440px) {
  .header {
    &-top-block {
      height: 80px;
    }
    &-nav {
      a {
        width: 142px;
        height: 54px;
        line-height: 54px;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .header {
    &-top-block {
      height: 80px;
    }
    &-nav {
      a {
        width: 123px;
        height: 80px;
        font-size: 18px;
        font-weight: bold;
        display: flex;
        align-items: center;

        span {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 6px;
          background-repeat: no-repeat;
          background-size: 100%;
          background-position: center;

          &.icon {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }
}
