@import "themes/scss/variables";

.checkbox-simple-container {
  display: flex;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 27px;
  align-items: center;
  &:last-child {
    margin-bottom: 0;
  }

  .checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkbox-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid #939598;
    margin-right: 10px;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkbox-checkmark {
    border: 2px solid $blue_primary_hard;
  }

  /* When the checkbox is checked, add a blue background */
  & input:checked ~ .checkbox-checkmark {
    background-color: $blue_primary;
    border: 1px solid $blue_primary;
  }

  /* When the checkbox is checked, add a blue background */
  & input:checked:hover ~ .checkbox-checkmark {
    background-color: $blue_primary_hard;
    border: 1px solid $blue_primary_hard;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkbox-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  & input:checked ~ .checkbox-checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  & .checkbox-checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .label-name {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
  }

  label {
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: normal;
    font-size: 14px;
    color: #000000;
    height: 27px;
    cursor: pointer;

    input[type="checkbox"] {
      width: 27px;
      height: 27px;
      margin-right: 20px;
    }
  }

  &.table-checkbox {
    padding: 0 !important;
    margin: 0 !important;

    input {
      margin: 0 !important;
    }
  }
}

.error {
  /* On mouse-over, add a grey background color */
  & :hover input ~ .checkbox-checkmark {
    border: 2px solid #ef3d23;
  }

  /* When the checkbox is checked, add a blue background */
  & input:checked ~ .checkbox-checkmark {
    border: 1px solid #ef3d23;
    background-color: #ef3d23 ;
  }

  /* When the checkbox is checked, add a blue background */
  & input:checked:hover ~ .checkbox-checkmark {
    border: 1px solid #ef3d23;
    background-color: #ef3d23 ;
  }
}

