@import "themes/scss/variables";

.buttonWithIcon {
  min-height: 36px;
  padding: 9px 17px;
  display: flex;
  align-items: center;
  color: $blue_primary;
  border: 1px solid $blue_primary;
  border-radius: 3px;
  font-weight: bold;

  svg {
    margin-right: 15px;
  }

  &--disabled {
    background-color: $grey_very_light;
    border-color: $grey_very_light;
    color: $white;
    cursor: initial;
  }
}

.tool-btn {
  &:hover {
    background: #f2f9ff;
    &:disabled {
      background-color: $grey_very_light;
    }
  }
  &:active {
    background: #dcedfe;
  }
}
.showHideDropDown {
  background-color: #ffffff;
  &:hover {
    background: #f8f8fc;
  }
  &:active {
    background: #e5e5e5;
  }
}
.paste-btn,
.copy-to-board-btn {
  background: #3399fa;
  color: #ffffff;
  &:hover {
    background: #0b85ff !important;
  }
  &:active {
    background: #006edc !important;
  }
  &:disabled {
    background: #add6ff !important;
  }
}
.copy-to-board-btn {
  width: 177px;
  height: 36px;
  padding: 9px 14px;
}
@media screen and (max-width: 1000px) {
  .buttonWithIcon {
    > span {
      display: none;
    }
  }
}
