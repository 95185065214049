.side-bar-container {
  position: relative;
  width: 15%;
  height: 100%;
  background-color: #1b384e;
  color: white;

  .side-bar-items-wrapper {
    // margin-top: 15%;
    // border-top: 1px solid black;

    .logo {
    }

    .side-bar-item {
      display: flex;
      align-items: center;
      border-bottom: 1px solid white;
      text-decoration: none;
      color: white;
      padding: 15px 20px;

      &.selected {
        background-color: white;
        color: black;
        .icon {
          filter: invert(0%);
        }
      }

      .icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        background-size: cover;
        filter: invert(100%);
      }

      .home {
        background-image: url("~assets/svg/home.svg");
      }

      .users {
        background-image: url("~assets/svg/users.svg");
      }
    }
  }

  & .log-out {
    height: 30px;
    width: 100%;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid white;
    position: absolute;
    bottom: 20px;
    cursor: pointer;
  }
}
