.users {
  max-height: 75vh !important;
  min-height: fit-content !important;
  .thead {
    position: sticky;
    top: 0;
    .th {
      width: 200px !important;
      font-size: 15px !important;
    }
  }
  .tbody .td {
    width: 200px;
    padding: 0px;
    height: 50px;
    .cell-action {
      width: 80px;
      color: white;
      height: 30px;
      border-radius: 3px;
    }
  }
}

.table-footer {
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  & .per-page-wrapper {
    height: 30px;
    display: flex;
    align-items: center;
    background-color: #1b384e;
    border-radius: 3px;
    width: 140px;
    font-weight: 600;
    & div {
      padding-top: 5px;
      color: white;
      border-radius: 3px;
      height: 30px;
      width: 80px;
      text-align: center;
    }
    & select {
      margin-left: 5px;
      font-size: inherit;
      font-weight: 600;
      border: none;
      border-radius: 2px;
      width: 50px;
    }
  }
  & .pagination-wrapper {
    display: flex;
    align-items: center;
    & ul {
      display: flex;
      & li {
        display: inline-block;
        padding: 5px;
        margin: 0 2px;
        width: 20px;
        text-align: center;
        height: 20px;
        border-radius: 50%;
        & a {
          cursor: pointer;
        }
        // cursor: pointer;
        // background-color: rgba(27,56,78,0.1);
        &:hover {
          background-color: rgba(27, 56, 78, 0.5);
        }
        &.selected {
          background-color: #1b384e;
          color: white;
        }
        &.disabled {
          & a {
            cursor: initial;
          }
          & img {
            filter: opacity(0.3);
          }
          &:hover {
            background: none;
          }
        }
        &.next {
          margin-right: 0;
        }
        &.previous {
          margin-left: 0;
        }
      }
    }
    & .first-last-icons {
      padding: 5px;
      width: 30px;
      text-align: center;
      height: 30px;
      border-radius: 50%;
      cursor: initial;

      &:hover {
        background-color: rgba(27, 56, 78, 0.5);
      }
      &.disabled {
        & img {
          filter: opacity(0.3);
          cursor: initial;
        }
        &:hover {
          background: none;
        }
      }
      & img {
        cursor: pointer;
        margin-top: 3px;
      }
    }
  }
}
