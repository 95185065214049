.switchcell-wrapper{
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.tr.error .td.courtError .switchcell-wrapper{
    border: 2px solid red;
    border-inline-style: none;
}
.tr.error .td.courtError:nth-child(8) .switchcell-wrapper{
    border-left: 2px solid red;;
}
.tr.error .td.courtError:nth-last-child(3) .switchcell-wrapper{
    border-right: 2px solid red;;
}