@import "themes/scss/variables";
@import "react-day-picker/lib/style.css";

.dashboardCont {
  max-width: 100%;
  height: 100%;

  .action-btn {
    &.bell {
      width: 24px;
      height: 28px;
    }
    &.disable-c {
      cursor: not-allowed;
    }
  }

  &.header-toggled {
    max-height: calc(100vh - 150px);
  }

  &-tools {
    padding: 40px 0 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    button {
      margin-left: 26px;
      width: 163px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-left: auto;
        width: 136px;
      }
    }

    .showHideDropDown {
      height: 36px;
      padding: 10px 0 11px 0px;
      display: flex;
      align-items: center;
      color: $black;
      border: 1px solid $black;
      border-radius: 3px;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      svg {
        path {
          fill: $black;
        }
      }
      .label {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          margin-left: 9px;
        }
      }

      .wrapper {
        width: max-content;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
      .content {
        border: 1px solid #000000;
      }
    }
  }

  .eventNameInput {
    width: 100%;
    font-weight: 500;
    font-size: 12px;
    border: none;
    outline: none;
    text-align: center;

    &::placeholder {
      text-align: center;
    }
  }

  &-date-modal {
    .wrapper {
      min-width: 896px;
      padding: 39px 40px 30px;
      // height: 524px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;

      p {
        margin-bottom: 29px;
        font-weight: bold;
        font-size: 19px;
      }

      .footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .clearBtn {
          margin-right: 31px;
          text-decoration: underline;
          font-size: 17px;
          &:hover {
            color: #939598;
          }
        }

        .saveBtn {
          position: relative;
          max-width: 112px;
          width: 100%;
          height: 46px;
          margin: 0;
          font-size: 17px;
          transition: all 0.3s ease-in-out;
          svg {
            display: none;
          }
          span {
            opacity: 1;
            animation: fade-in 0.3s linear;
          }
          @keyframes fade-in {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
          &:active {
            top: 2px;
            background: #3d3d3e !important;
          }
          &:focus {
            background: #000000 !important;
          }
          &:disabled {
            background: $light_grey !important;
          }
        }

        .animateSaveBtn {
          position: relative;
          max-width: 112px;
          width: 100%;
          height: 46px;
          margin: 0;
          font-size: 17px;
          transition: all 0.3s ease-in-out;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px 20px 15px 20px !important;
          span {
            opacity: 0;
            animation: fade-out 0.3s linear;
          }
          @keyframes fade-out {
            0% {
              opacity: 1;
            }
            100% {
              opacity: 0;
            }
          }
          &:active {
            top: 2px;
            background: #3d3d3e !important;
          }
          &:focus {
            background: #3d3d3e !important;
          }
          &:disabled {
            background: $light_grey !important;
          }
          svg {
            position: absolute;
            width: 100%;
            height: 50%;
            transform: translateY(0.25rem);
            stroke-dasharray: 80;
            stroke-dashoffset: 80;
            animation: animateCheck 0.35s forwards 1.25s ease-out;
          }
          @keyframes animateCheck {
            from {
              stroke-dashoffset: 80;
            }
            to {
              stroke-dashoffset: 0;
            }
          }
        }
      }
    }
  }
}

.DayPickerInput-Overlay {
  z-index: 99;
  .DayPicker-Day {
    display: grid;
    align-items: center;
  }
  .DayPicker {
    padding: 20px 0;
    position: fixed;
    background: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  }
}
.DayPicker-disabled {
  cursor: not-allowed;
}

.dashboardCont .table .DayPickerInput {
  input {
    //color: #989898;
    cursor: pointer;
  }

  input:disabled {
    color: #000;
    background: white;
    cursor: default;
  }
}

// TODO REMOVE COMMENTED CODE AFTER PROD
.react-table-container {
  //  padding: 1rem;

  &.table {
    //border: 1px solid #ddd;

    .DayPickerInput-Overlay {
      top: 20px;
      bottom: unset;
    }

    .tr {
      :last-child {
        .td {
          //border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      //padding: 5px;
      //border-bottom: 1px solid #ddd;
      //border-right: 1px solid #ddd;
      //background-color: #fff;
      //overflow: hidden;

      :last-child {
        //border-right: 0;
      }

      .resizer {
        //display: inline-block;
        //width: 5px;
        //height: 100%;
        //position: absolute;
        //right: 0;
        //top: 0;
        //transform: translateX(50%);
        //z-index: 1;

        &.isResizing {
          //background: red;
        }
      }
    }

    &.sticky {
      //overflow: scroll;
      //.header,
      //.footer {
      //  position: sticky;
      //  z-index: 1;
      //  width: fit-content;
      //}

      .header {
        //top: 0;
        //box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        //bottom: 0;
        //box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        //position: relative;
        //z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        //box-shadow: 2px 0px 3px #ccc;
      }

      [data-sticky-first-right-td] {
        //box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
}

.react-switch-handle {
  box-shadow: unset !important;
}

//.acceptable-records .DayPickerInput-Overlay {
//  top: unset !important;
//  bottom: 0 !important;
//}

// width 1000

@media screen and (max-width: 1000px) {
  .dashboardCont {
    &-tools {
      button {
        font-size: 13px;
        line-height: 16px;
        width: 107px;
      }
      .showHideDropDown {
        width: 122px !important;
        font-size: 13px !important;
        line-height: 16px !important;
      }
    }
  }
}
