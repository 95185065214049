@import "./fonts/_azoSans.scss";
@import "./fonts/inter.scss";

* {
  margin: 0;
  padding: 0;
  font-family: "Inter Regular";
}

button {
  border: none;
  background: transparent;
  cursor: pointer;
}

html,
body,
#root,
.app {
  height: 100vh;
  overflow-y: auto;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//global clases
.container {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  padding: 0 45px;
  box-sizing: border-box;
}

.Toastify__toast-container--top-center {
  width: 75% !important;
  text-align: center;
}

@media screen and (max-width: 1440px) {
  .container {
    padding: 0 52px;
  }
}

@media screen and (max-width: 1000px) {
  .container {
    padding: 0px;
  }
}
