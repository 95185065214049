@import "themes/scss/variables";

.audienceTable {
  max-height: 100%;
  overflow: auto;
  border-spacing: 0;

  .thead {
    width: 580px;
    .th {
      background: #f8f7f7 !important;
      border: 1px solid #e7e8ea;
    }
  }
  // .tfoot {
  //   background: #fff;
  //   border-bottom: 2px solid $grey_light;
  //   border-top: 2px solid $grey_light;
  // }

  // .tbody {
  //   .td {
  //     // border-bottom: none;
  //   }
  // }

  // .tr {
  //   // border-bottom: 2px solid $grey_light;
  //   // border: 1px solid #e7e8ea;
  // }

  .th {
    font-size: 16px;
    font-weight: bold;
    user-select: none;
  }
  .tr {
    display: flex;
    flex: 1 0 auto;
    min-width: 0px;
    display: flex;
    align-items: center;
  }
  .tr-inner {
    width: 580px;
    display: flex;
    align-items: center;
  }
  .tr > svg {
    margin: 0 11px;
    cursor: pointer;
  }
  .td {
    font-size: 12px;
    border: 1px solid #e7e8ea;
  }

  .th,
  .td {
    margin: 0;
    box-sizing: initial;
    background-color: #fff;
    height: 41px;
    box-sizing: border-box;
    min-width: 0px;
    width: 200px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .td > input {
    height: 39px;
    width: 100%;
    text-align: center;
    border: none;
    cursor: default;
    // border-bottom: 1px solid black;
    outline: none !important;
    &:focus {
      border-bottom: 1px solid black;
    }
  }

  .td > input:read-only {
    cursor: default;
    border: none !important;
  }

  .session-time-block {
    display: flex;
    align-items: center;

    input {
      width: 72px;
      height: 32px;
      position: relative;
      z-index: 1;
      font-size: 12px;
      background-color: white;
      border-radius: 3px;
      border: 1px solid #dedede;
      outline: none;

      &:first-child {
        margin-right: 12px;
      }
    }
  }

  &.sticky {
    .thead,
    .tfoot {
      min-width: max-content;
      position: sticky;
      z-index: 1;
    }

    .thead {
      top: 0;
      box-shadow: 0px 3px 3px #ccc;
    }

    .tfoot {
      bottom: 0;
    }

    .tbody {
      position: relative;
      z-index: 0;
    }

    [data-sticky-td] {
      position: sticky;
    }

    [data-sticky-last-left-td] {
      box-shadow: 2px 0px 3px #ccc;
    }

    [data-sticky-first-right-td] {
      box-shadow: -2px 0px 3px #ccc;
    }
  }

  .editable-cell {
    border: none;
    font-size: 13px;

    &::placeholder {
      text-align: center;
      font-style: italic;
      color: $grey_light;
    }
  }

  .uneditable-cell {
    font-size: 12px;
  }
}
