@import "themes/scss/variables";

.table {
  max-height: 100%;
  min-height: 600px;
  overflow: scroll;
  height: 100%;
  border-spacing: 0;
  position: relative;

  .thead {
    .th {
      white-space: nowrap;
      font-size: 15px;
    }
  }
  .tfoot {
    background: #fff;
    border-bottom: 2px solid $grey_light;
    border-top: 2px solid $grey_light;
  }

  .tbody {
    .td {
      border-bottom: 2px solid $grey_light;
      font-size: 14px;
      text-align: center;
    }
  }

  .th {
    background: #f8f7f7;
    font-size: 16px;
    font-weight: bold;
    user-select: none;
  }

  .td {
    background: #fff;
    font-size: 12px;
  }

  .th,
  .td {
    margin: 0;
    padding: 17px 0.5rem;
    box-sizing: initial;
  }

  .session-time-block {
    display: flex;
    align-items: center;

    .session-time {
      text-transform: uppercase;
    }

    input {
      width: 72px;
      height: 32px;
      position: relative;
      z-index: 1;
      font-size: 12px;
      background-color: white;
      border-radius: 3px;
      border: 1px solid #dedede;
      outline: none;
      color: black;

      &[name="start"] {
        margin-right: 12px;
      }
    }

    input[type="time"]::-webkit-calendar-picker-indicator {
      min-width: 100%;
      position: absolute;
      right: 0;
      z-index: 0;
      background: transparent;
    }

    .session-time {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 72px;
      height: 32px;
      border-radius: 3px;
      background-color: #dedede;
      font-size: 12px;
      border: 1px solid transparent;

      &:first-child {
        margin-right: 12px;
      }
    }
  }

  &.sticky {
    .thead,
    .tfoot {
      min-width: max-content;
      position: sticky;
      z-index: 1;
    }

    .thead {
      top: 0;
      box-shadow: 0px 3px 3px #ccc;
    }

    .tfoot {
      bottom: 0;
    }

    .tbody {
      position: relative;
      z-index: 0;
    }

    [data-sticky-td] {
      position: sticky;
    }

    [data-sticky-last-left-td] {
      // box-shadow: 2px 0px 3px #ccc;

      &:first-child {
        box-shadow: none !important;
        text-align: center;
      }
    }

    [data-sticky-first-right-td] {
      box-shadow: -2px 0px 3px #ccc;
    }
  }

  &.sticky {
    overflow: scroll;
    .header,
    .footer {
      position: sticky;
      z-index: 1;
      width: fit-content;
    }

    .header {
      top: 0;
      box-shadow: 0px 3px 3px #ccc;
    }

    .footer {
      bottom: 0;
      box-shadow: 0px -3px 3px #ccc;
    }

    .body {
      position: relative;
      z-index: 0;
    }

    [data-sticky-td] {
      position: sticky;
    }

    [data-sticky-last-left-td] {
      box-shadow: 2px 0px 3px #ccc;
    }

    [data-sticky-first-right-td] {
      box-shadow: -2px 0px 3px #ccc;
    }
  }
  .actions-block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      margin-right: 14px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .DayPickerInput {
    padding-right: 0.5rem;
    position: relative;

    &::after {
      content: "";
      display: inline-block;
      min-width: 16px;
      min-height: 16px;
      background-image: url(~assets/svg/calendar-icon.svg);
      position: absolute;
      right: 0.5rem;
      top: 0;
    }

    &-Overlay {
      position: absolute;
      bottom: 0;
    }
    input {
      width: 100%;
      text-align: center;
      outline: none;
      border: none;
      cursor: pointer;
      box-sizing: border-box;
      background-color: transparent !important;
    }
  }

  .table-button {
    max-width: 91px;
    width: 100%;
    padding: 0;
    height: 34px;
  }
  .filter-select-block {
    width: max-content;
    position: relative;
    z-index: 1;

    img {
      width: 14px;
      height: 14px;
      display: block;
      position: absolute;
      z-index: -1;
      right: -5px;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .filter-select {
      width: 80px;
      box-sizing: border-box;
      max-width: 100%;
      padding: 0 20px 0 5px;
      font-size: 12px;
      font-weight: 500;
      border: none;
      outline: none;
      height: 34px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-color: transparent;
    }
  }
}

@media screen and (max-width: 1440px) {
  .table {
    .thead {
      .th {
        white-space: nowrap;
        font-size: 14px;
      }
    }
  }
}
