@import "themes/scss/variables";

$left_side_blocks_distance_from_left: 91px;

.audienceCont {
  max-height: calc(100vh - 350px);
  height: 100%;

  &-tools {
    padding: 39px 0 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 10;
    box-shadow: 0px 5px 5px -5px rgba(34, 60, 80, 0.6);

    button {
      margin-left: 24px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &-board {
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &-left-side {
    max-width: 410px;
    width: 100%;
  }
  &-right-side {
    height: 100%;
    width: 100%;
    margin-left: 144px;
  }

  &-create-group {
    width: 100%;
    height: 90px;
    padding-left: $left_side_blocks_distance_from_left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: $grey_dark;
    color: $white;

    svg {
      margin-right: 23px;
    }
  }

  &-group-block {
    background-color: $grey_light;
  }
}

.groupButton {
  width: 100%;
  height: 77px;
  padding-left: $left_side_blocks_distance_from_left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 2px solid $white;

  label {
    margin-right: 130px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 13px;
    color: $black;
    font-weight: 500;
    font-style: italic;

    input {
      margin-right: 20px;
      width: 27px;
      height: 27px;
    }
  }

  span {
    width: 47px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    border-radius: 34px;
    background-color: $grey_dark_light;
    color: $grey_very_dark;
  }

  &.active {
    background-color: #ccc;
  }
}

.audience-popup {
  > .content {
    height: 575px;
  }
}

.audience-popup-body {
  width: 1077px;
  height: 575px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  overflow: hidden;
  .disabled-table {
    .td,
    .th {
      cursor: not-allowed !important;
    }
  }
}

.audience-notifications {
  max-width: 437px;
  width: 100%;
  height: calc(100% - 109px);
  margin-top: 109px;
  display: flex;
  flex-direction: column;
  margin-left: 45px;
  .audience-notifications-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
    h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 17px;
      line-height: 20px;
      margin-bottom: 15px;
    }
    > span {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 10px;
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
  .disabled {
    cursor: not-allowed;
    h1,
    p {
      color: #a9a9a9;
    }
    input,
    label {
      cursor: not-allowed !important;
      pointer-events: none !important;
    }
    input:checked ~ .checkbox-checkmark {
      background: #a9a9a9 !important;
      border: 1px solid #a9a9a9 !important;
    }
  }
  .buttons-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    margin: auto 74px 58px 0;
  }
}

.audience-table-wrapper {
  max-width: 643px;
  width: 100%;
  height: 100%;
  max-height: 420px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 53px;
  margin-bottom: 53px;
  padding-left: 67px;
  .table-wrapper {
    width: 635px;
    overflow: auto;
  }
  .audience-table-header {
    max-width: 580px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-right: 54px;
    margin-bottom: 10px;

    h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 17px;
      line-height: 24px;
      color: #000000;
    }
  }
}

.audience-popup-body-past {
  > div:nth-child(3) {
    > div {
      padding: 79px 40px 38px 40px;
    }
    .text-block {
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 19px;
        line-height: 24px;
        text-align: center;
        margin: 0;
      }
    }
  }
}
