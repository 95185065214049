@font-face {
  font-family: "Inter Thin";
  src: local("Inter Thin"), url("~assets/fonts/inter/Inter-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Inter SemiBold";
  src: local("Inter SemiBold"), url("~assets/fonts/inter/Inter-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter Regular";
  src: local("Inter Regular"), url("~assets/fonts/inter/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter Medium";
  src: local("Inter Medium"), url("~assets/fonts/inter/Inter-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Inter Light";
  src: local("Inter Light"), url("~assets/fonts/inter/Inter-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Inter ExtraLight";
  src: local("Inter ExtraLight"), url("~assets/fonts/inter/Inter-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Inter ExtraBold";
  src: local("Inter ExtraBold"), url("~assets/fonts/inter/Inter-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter Bold";
  src: local("Inter Bold"), url("~assets/fonts/inter/Inter-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter Black";
  src: local("Inter Black"), url("~assets/fonts/inter/Inter-Black.ttf") format("truetype");
}
