.loading {
  margin: 0 auto;
  width: max-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 111;
}
.loading-small {
  position: absolute;
  margin: auto 0;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring-small {
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fcf;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #3399fa transparent transparent transparent;
}
.lds-ring-small div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 22px;
  height: 22px;
  margin: 8px;
  border: 2px solid #fcf;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #3399fa transparent transparent transparent;
}

.lds-ring div:nth-child(1),
.lds-ring-small div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2),
.lds-ring-small div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3),
.lds-ring-small div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
