@import "themes/scss/variables";
@import "react-day-picker/lib/style.css";

.new {
  max-height: calc(100% - 235px);
  height: 70%;

  &-tools {
    padding: 41px 0 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    button {
      width: 163px;
      height: 36px;
      margin-left: 26px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:first-child {
        margin-left: 0;
      }
    }

    .showHideDropDown {
      width: 136px;
      height: 36px;
      padding: 10px 0 11px 0px;
      display: flex;
      align-items: center;
      color: $black;
      border: 1px solid $black;
      border-radius: 3px;
      font-weight: bold;
      margin-left: auto;
      font-size: 14px;
      line-height: 17px;
      svg {
        path {
          fill: $black;
        }
      }
      .label {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          margin-left: 9px;
        }
      }

      .wrapper {
        width: max-content;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        label {
          margin-top: 4px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: bold;
          font-size: 16px;
          color: #000000;

          input[type="checkbox"] {
            width: 27px;
            height: 27px;
            margin-right: 20px;
          }
        }
      }
      .content {
        border: 1px solid #000000;
      }
    }
  }

  &-date-modal {
    .wrapper {
      min-width: 896px;
      padding: 39px 40px 30px;
      // height: 524px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;

      p {
        margin-bottom: 29px;
        font-weight: bold;
        font-size: 19px;
      }

      .footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .clearBtn {
          margin-right: 31px;
          text-decoration: underline;
          font-size: 17px;
          &:hover {
            color: #939598;
          }
        }

        .saveBtn {
          position: relative;
          max-width: 112px;
          width: 100%;
          height: 46px;
          margin: 0;
          font-size: 17px;

          &:active {
            top: 2px;
            background: #3d3d3e !important;
          }
          &:focus {
            background: #000000 !important;
          }
          &:disabled {
            background: $light_grey !important;
          }
        }
      }
    }
  }

  .defaultCell {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 1000px) {
  .new {
    &-tools {
      button,
      .add-row {
        font-size: 13px;
        line-height: 16px;
        width: 107px;
        margin-left: 14px;
        &.submit-all {
          margin-left: 14px;
        }
      }
      .showHideDropDown {
        width: 122px !important;
        font-size: 13px;
        line-height: 16px;
      }
    }
  }
}
