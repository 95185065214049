@import "themes/scss/variables";

.defaultButton {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 3px;
}

.btn-submitted {
  width: 34px !important;
  height: 34px !important;
}

.btn-ready {
  background: #3399ff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: $white;
  text-transform: capitalize;
  &:hover {
    background: #0b85ff !important;
  }
  &:active {
    background: #006edc !important;
  }
}

.btn-failed {
  border: 1px solid red;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #000;
  text-transform: capitalize;
}

.label-ready,
.audience-add-row {
  height: 33px;
  width: 81px;
  border: 1px solid #3399ff;
  box-sizing: border-box;
  background-color: #ffffff !important;
  color: #3399ff !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-transform: capitalize;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #3399ff !important;
    color: #ffffff !important;
    box-shadow: 0px 4px 14px rgba(51, 153, 255, 0.4);
    border-radius: 5px;
  }
  &:active {
    background-color: #3683cf !important;
    color: #ffffff !important;
  }
  &:disabled {
    background-color: #add6ff !important;
    color: #ffffff !important;
    border: none;
    pointer-events: none;
  }
}
.audience-add-row {
  box-shadow: none;
  &:hover {
    box-shadow: none;
  }
}

.label-ready {
  &:focus {
    background-color: #3683cf !important;
    color: #ffffff !important;
  }
}

.audience-add-row {
  width: 106px;
  height: 36px;
  margin: 0 0 0 auto;
  display: flex;
  align-items: baseline;
  padding: 9px 0 11px 0;
  svg {
    path {
      fill: #3399ff;
    }
  }
  &:hover {
    svg {
      path {
        fill: #ffffff;
      }
    }
  }
}

.label-live,
.btn-live {
  height: 33px;
  width: 81px;
  border: 1px solid #ef3d23;
  box-sizing: border-box;
  background-color: #ffffff !important;
  color: #ef3d23 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-transform: capitalize;
  transition: all 0.2s ease-in-out;
  .live-icon-wrapper {
    width: 17px;
    height: 17px;
    border: 1px solid #ef3d23;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
  }
  &:hover {
    background-color: #cf311a !important;
    color: #ffffff !important;
    border-radius: 5px;
    .live-icon-wrapper {
      border: 1px solid transparent;
    }
  }
  &:active {
    background-color: #c01900 !important;
    color: #ffffff !important;
    .live-icon-wrapper {
      border: 1px solid transparent;
    }
  }
}

.btn-live {
  border: 1px solid #ef3d23 !important;
  background-color: #ef3d23 !important;
  color: #ffffff !important;
  .live-icon-wrapper {
    width: 17px;
    height: 17px;
    border: 1px solid #ef3d23;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
  }
  &:hover {
    background-color: #cf311a !important;
    border-radius: 5px;
    .live-icon-wrapper {
      border: 1px solid transparent;
    }
  }
  &:active {
    background-color: #c01900 !important;
    .live-icon-wrapper {
      border: 1px solid transparent;
    }
  }
}

.btn-ended {
  text-transform: capitalize;
  font-size: 14px;
}

//save btn
.saveBtn {
  position: relative;
  max-width: 112px;
  width: 100%;
  height: 46px;
  margin: 0;
  font-size: 17px;
  transition: all 0.15s ease-in-out;
  svg {
    display: none;
  }
  span {
    opacity: 1;
    animation: fade-in 0.15s linear;
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.saveBtn,
.login-submit {
  background-color: #000000;
  color: #ffffff;
  &:active {
    top: 2px;
    background: #3d3d3e !important;
  }
  &:focus {
    background: #000000 !important;
  }
  &:disabled {
    background: $light_grey !important;
  }
}

.login-submit-active {
  background-color: #3d3d3e !important;

  &:focus {
    background: #3d3d3e !important;
  }
}

.animateSaveBtn {
  position: relative;
  max-width: 112px;
  width: 100%;
  height: 46px;
  margin: 0;
  font-size: 17px;
  transition: all 0.15s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px 15px 20px !important;
  background-color: #3d3d3e;
  span {
    opacity: 0;
    animation: fade-out 0.15s linear;
  }
  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  &:active {
    top: 2px;
    background: #3d3d3e !important;
  }
  &:focus {
    background: #3d3d3e !important;
  }
  &:disabled {
    background: $light_grey !important;
  }
  svg {
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
    transform: translateY(0.25rem);
    stroke-dasharray: 80;
    stroke-dashoffset: 80;
    animation: animateCheck 0.35s forwards ease-out;
  }
  @keyframes animateCheck {
    from {
      stroke-dashoffset: 80;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
}

//edit btn

.edit-btn {
  width: 31px;
  height: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  color: #000000 !important;
  margin: 0 30px 0 0 !important;
  &:hover {
    color: #939598 !important;
  }
}

.reset-btn {
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-decoration-line: underline;
  color: #000000 !important;
  background: transparent !important;
  width: 95px !important;
  height: 18px !important;
  margin-left: 17px !important;
  padding: 0;
  &:hover {
    color: #939598 !important;
  }
}

.submit-all {
  background: #010101;
  border-radius: 5px;
  color: #ffffff;
  width: 100px !important;
  height: 36px !important;
  padding: 0 !important;
  margin-left: 26px;
  margin-right: 0;
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  &:active,
  &:focus {
    background: #3d3d3e;
  }
  &:disabled {
    background: $light_grey;
  }
}

.add-row {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  width: 163px;
  height: 36px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3399fa;
  color: #ffffff;
  border-radius: 3px;
  margin: 0 0 0 26px;
  &:hover {
    background: #0b85ff !important;
  }
  &:active {
    background: #006edc !important;
  }
  &:disabled {
    background: #add6ff !important;
  }
}

@media screen and (max-width: 1000px) {
  .reset-btn {
    height: 16px !important;
    margin-left: 4px !important;
    font-size: 13px;
    line-height: 16px;
  }
}
