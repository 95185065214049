.action {
  width: 81px;
  height: 33px;
  background: #010101;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  border-radius: 3px;
}
