.create-user-container {
  max-width: 1405px;
  width: 85%;
  height: auto;
  margin: 25px auto;

  .create-user-header {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
  }

  h1 {
    text-align: center;
    margin-bottom: 25px;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
    .sections-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin-bottom: 25px;
      .card {
        max-width: 423px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        p {
          font-size: 24px;
        }
        .inputs-wrapper {
          display: flex;
          flex-direction: column;
          height: 700px;
          width: 90%;
          justify-content: flex-start;
          border: 1px solid black;
          padding: 0 10px;
          span {
            margin: 10px 0;
            width: 100%;
            height: 60px;
            display: flex;
            flex-wrap: wrap;
            border-bottom: 1px solid #000000;
            label,
            input {
              flex: 1 1 100%;
            }
            label {
              margin-bottom: 5px;
            }

            input {
              border: none;
              font-size: 18px;
              &:focus {
                outline: none;
              }
            }
            select {
              height: 25px;
              margin-right: 10px;
            }
            .label-file-input {
              border: 1px solid black;
              background: lightgrey;
              width: 100px;
              border-radius: 3px;
              display: inline-flex;
              align-items: center;
              padding-left: 5px;
              flex: none;
              cursor: pointer;
              height: 30px;
            }
            .filename {
              font-size: 14px;
              padding-left: 5px;
              display: inline-flex;
              max-width: 200px;
              word-break: break-word;
              align-items: center;
            }
          }
          .error-border {
            border-bottom: 3px solid red;
          }
        }
      }
      .card:nth-child(2) {
        .inputs-wrapper {
          span:nth-child(3),
          span:nth-child(6) {
            margin-bottom: 35px;
          }
        }
      }
      .card:nth-child(2),
      .card:nth-child(3) {
        span {
          label,
          .react-switch {
            flex: 1 1 50%;
          }
        }
      }
    }
  }
}
