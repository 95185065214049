@import "themes/scss/variables";

.login {
  height: 100%;
  padding-top: 55px;
  display: flex;
  flex-direction: column;

  &-error-message {
    position: relative;
    top: 16px;
    font-size: 14px;
    text-align: center;
    color: #e44f35;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid $grey_medium;

    p {
      text-align: center;
      font-weight: bold;
      font-size: 24px;

      &:last-child {
        width: 183px;
      }
    }
  }

  &-submit {
    width: 112px;
    height: 46px;
    margin-top: 45px;
    border-radius: 5px;
    font-size: 17px;
  }

  &-container {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  &-content {
    max-width: 460px;
    width: 100%;
    height: 100%;
    max-height: 453px;
    padding: 82px 39px 66px;
    margin-top: 124px;
    border-radius: 2px;
    background-color: #f1f1f2;
    box-sizing: border-box;

    form {
      .field-label {
        display: block;
        font-weight: bold;
        font-size: 16px;

        &:first-child {
          margin-bottom: 25px;
        }

        .input-wrapper {
          height: 44px;
          margin-top: 10px;
          padding: 0 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $white;

          &:focus-within {
            outline: auto;
          }

          input {
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
          }
        }
      }
    }
  }

  &-links-block {
    margin-top: 19px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: bold;
      font-size: 16px;

      input {
        margin-right: 14px;
        width: 27px;
        height: 27px;
        -moz-appearance: none;
        -webkit-appearance: none;
        -o-appearance: none;
        border: 1px solid #939598;
        border-radius: 3px;
        position: relative;
        &:checked {
          background-color: #3399ff;
          border: 1px solid #3399ff;
        }
        &:checked::after {
          content: url("../../assets/svg/tick-mark.svg");
          position: absolute;
          top: 6.5px;
          right: 4.5px;
          color: #fff;
        }
        &:hover:not(:checked) {
          border: 2px solid #3399ff;
        }
        &:hover:checked {
          background-color: #006edc;
          border: 1px solid #006edc;
          outline: none !important;
        }
      }
    }

    span {
      font-weight: 400;
      font-size: 14px;
      text-decoration: none;
      color: $light_grey;
      cursor: pointer;
    }
  }
}

.passwordInput {
  &-hide-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
