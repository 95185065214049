$grey_medium: #a9abae;
$grey_normal: #939598;
$grey_light: #d1d3d4;
$athens_gray: #f1f1f2;
$grey_medium: #a9abae;
$grey_cold_light: #d1d3d4;
$grey_very_light: #dedede;
$grey_dark: #828282;
$grey_very_dark: #676666;
$grey_dark_light: #bfbfbf;
$blue_primary: #3399fa;
$blue_primary_2: #3399ff;
$blue_primary_hard: #006edc;
$black: #000000;
$white: #ffffff;
$btn_orange: #fbb317;
$light_grey: #808080;
$light_black: #010101;
$light_green: #3fbf3f;
