.frequency {
  width: max-content;
  position: relative;
  z-index: 1;

  img {
    width: 14px;
    height: 14px;
    display: block;
    position: absolute;
    z-index: -1;
    right: -5px;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  select {
    width: 80px;
    box-sizing: border-box;
    max-width: 100%;
    padding: 0 20px 0 5px;
    font-size: 12px;
    font-weight: 500;
    border: none;
    outline: none;
    height: 34px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
  }
}

