.settings {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .action-btn {
    &.bell {
      width: 24px;
      height: 28px;
    }
  }

  button {
    margin-right: 14px;

    &:last-child {
      margin-right: 0;
    }
  }
}
