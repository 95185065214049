.confirmationPopUp {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.85);

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 79px 45px 38px;
    width: 552px;
    height: 244px;
    background: #ffffff;
    border: 1px solid #a9abae;
    border-radius: 3px;
    box-sizing: border-box;
  }
  .text-block {
    .description {
      text-align: center;
      font-weight: 500;
      font-size: 19px;
    }
    p:nth-child(2) {
      margin-top: 15px;
    }
  }

  .actions-block {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      width: 112px;
      height: 46px;
      border: 1px solid transparent;
      border-radius: 5px;
      font-weight: normal;
      font-size: 17px;
      line-height: 22px;
      transition: all 0.3s ease-in-out;
      &.negative {
        border-color: #3399ff;
        color: #3399ff;
        background: #ffffff;

        &:hover {
          background: #f2f9ff;
        }
        &:active,
        &:focus {
          background: #dcedfe;
          border: 1px solid #3399ff;
        }
        &:disabled {
          background: #dedede;
          border: 1px solid #dedede;
        }
      }
      &.positive {
        margin-left: 21px;
        background-color: #3399ff;
        color: white;
        &:hover {
          background-color: #0b85ff;
        }
        &:active,
        &:focus {
          background-color: #006edc;
        }
        &:disabled {
          background-color: #add6ff;
        }
      }
    }
  }
}
