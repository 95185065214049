.users-container{
    width: 85%;
    height: 80%;
    & .users-top-bar{
        display: flex;
        margin: 2rem;
        justify-content: space-between;
    }
    & .add-user-button{
        height: 40px;
        width: 180px;
        border-radius: 5px;
        background: #000a4e;
        color: white;
        font-size: 16px;
    }
    & .admin-modal{
        height: 200px;
        width: 400px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 3px;

        & .confirmation-button{
            width: 80px;
            height: 40px;
            border-radius: 3px;
            background:#000a4e;
            color:white;
            margin-top: 20px;
        }
    }

}
