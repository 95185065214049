@import "themes/scss/variables";

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: $black !important;
}

.DayPicker-Day--disabled {
  cursor: no-drop !important;
}

.DayPickerInput {
  input[disabled] {
    cursor: default !important;
    color: #000;
  }
}

.DayPicker-Day:has(.isLast) {
  background-color: red !important;
}

.dashboardCont-date-modal {
  .DayPicker-Day[tabindex="-1"]:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: #dedede !important;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: #dedede !important;
  }

  .DayPicker-Day.DayPicker-Day--selected {
    &.DayPicker-Day--start {
      .isLast {
        background: $white !important;
        width: 75px;
        &:before {
          content: attr(data-day);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background: $black;
          align-items: center;
          display: flex;
          text-align: center;
          justify-content: center;
        }
        &:after {
          width: 80px;
          top: calc(100% / 3);
        }
      }
    }
    &.DayPicker-Day--start,
    &.DayPicker-Day--end {
      .DayPicker-CustomDay {
        &:after {
          background-color: $white;
        }
      }
    }
  }
}

.DayPicker-Day[tabindex="0"] .DayPicker-CustomDay {
  border-radius: 50% 0 0 50%;
}
