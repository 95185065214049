@import "themes/scss/variables";

.dropDown {
  $self: &;
  position: relative;

  &--show {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

    #{$self}-content {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &-content {
    margin: 0 auto;
    padding: 15px;
    position: absolute;
    left: -1px;
    right: -1px;
    top: 100%;
    z-index: 10;
    border-radius: 3px;
    background-color: $white;
  }
}
