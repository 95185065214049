@import "themes/scss/variables";

.settings-app {
  .defaultButton {
    margin-top: 30px;
    display: block;
    width: 112px;
    height: 46px;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    margin: 30px auto 0 auto !important;
  }

  > .content {
    max-width: 1405px;
    width: 100%;
    margin: 62px auto 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media (max-width: 1920px) {
      max-width: 1407px;
    }

    @media (max-width: 1000px) {
      margin: 48px auto 0;
      max-width: 1335px;
    }
    .card {
      max-width: 447px;
      width: 100%;

      .title {
        margin-bottom: 14px;
        font-size: 16px;
        line-height: 19px;
        font-weight: bold;
        text-align: center;
        @media (max-width: 1000px) {
          margin-bottom: 16px;
        }
      }

      &-content {
        height: 558px;
        padding: 52px 32px 58px;
        box-sizing: border-box;
        background: #f1f1f2;
        border-radius: 2px;

        .field {
          margin-bottom: 30px;
          &.label-block {
            margin-bottom: 36px;
          }
          &:last-child {
            margin-bottom: 0;
          }

          &.block-with-refs {
            .refs-block {
              margin-top: 12px;
              display: flex;
              align-items: center;
              justify-content: space-between;

              p {
                font-style: italic;
                font-weight: 500;
                font-size: 12px;
                color: #939598;
                cursor: pointer;
              }
            }
          }

          &.number-of-camera {
            label {
              flex-direction: row;
              justify-content: space-between;
            }
            .camera-select-div {
              width: 63px;
              height: 28px;
              position: relative;
            }
            .camera-select-div::after {
              content: url("../../../assets/svg/select-arrow.svg");
              position: absolute;
              right: 0;
              top: 0;
              width: 20px;
              height: 25px;
              background-color: #000000;
              pointer-events: none;
              padding-bottom: 3px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 0 4px 4px 0;
            }
            select {
              width: inherit;
              height: inherit;
              border: 1px solid #000000;
              box-sizing: border-box;
              border-radius: 4px;
              padding-left: 15px;
              outline: none;
            }
          }

          &.upload-block {
            label,
            > img {
              width: 181px;
              height: 89px;
              margin: 0 auto;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              background: #ffffff;
              border-radius: 16px;
              cursor: pointer;
              object-fit: contain;
              img {
                width: 37px;
                height: 31px;
                display: block;
              }

              input {
                border: none;
                width: max-content;
                height: max-content;
                display: none;
              }

              p {
                margin-left: 8px;
                line-height: 15px;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
              }
            }
            .filename {
              font-size: 14px;
              padding-left: 5px;
              display: inline-flex;
              padding-top: 5px;
              word-break: break-word;
              align-items: center;
            }
          }

          &.default-contacts {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 36px;
            label {
              width: 100%;
            }

            .tools {
              margin-left: 38px;
              display: flex;
              align-items: center;

              img {
                margin-left: 30px;
              }
            }
          }

          &.livestream-options {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 70px;

            label {
              min-width: 140px;
              margin-right: 60px;
            }
            .select-div {
              position: relative;
              float: left;
              width: 107px;
              height: 28px;
            }
            .select-div::after {
              content: url("../../../assets/svg/select-arrow.svg");
              position: absolute;
              right: 0;
              top: 0;
              width: 20px;
              height: 25px;
              background-color: #000000;
              pointer-events: none;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 0 4px 4px 0;
              padding-bottom: 3px;
            }
            .custom-select {
              width: inherit;
              height: inherit;
              border: 1px solid #000000;
              box-sizing: border-box;
              border-radius: 4px;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              padding-left: 15px;
              outline: none;
            }
          }

          label {
            display: flex;
            flex-direction: column;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;

            input {
              height: 40px;
              margin-top: 8px;
              background-color: transparent;
              border: none;
              border-bottom: 2px solid #a9abae;

              &:focus {
                border-bottom: 2px solid #000;
              }
            }
            .password-wrapper {
              position: relative;
              display: flex;
              align-items: center;
              svg {
                position: absolute;
                right: 0;
              }
              input {
                width: 100%;
              }
            }
          }
        }

        @media (max-width: 1920px) {
          padding: 44px 32px 38px;
        }
        @media (max-width: 1441px) {
          padding: 44px 20px 38px;
        }
        @media (max-width: 1000px) {
          padding: 40px 33px 39px 29px;
          height: 555px;
        }
      }
    }
    .card:first-child {
      @media (max-width: 1000px) {
        margin-left: 41px;
      }
    }
  }
  .confirmationPopUp {
    .content {
      width: 559px;
      height: 320px;
      .description {
        font-style: normal;
        font-weight: normal;
        font-size: 19px;
        line-height: 24px;
        text-align: center;
        color: #000000;
      }
    }
  }

  @import "themes/scss/input-reset";
}

.upload-logo {
  .content {
    width: 460px;
    height: 300px;
    background: #ffffff;
    border-radius: 3px;
  }
  .upload-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 58px 0 45px 0;
    font-size: 18px;
    & div:first-child {
      font-size: 22px;
      line-height: 28px;
      font-weight: 700;
      margin-bottom: 35px;
      height: 31px;
    }
    & button,
    label {
      font-size: 18px;
      line-height: 23px;
      font-weight: 500;
      height: 31px;
    }
    & .red {
      color: #ef3d23;
      margin-bottom: 18px;
      height: 32px;
    }
    & .blue {
      & label {
        color: #3399ff;
        cursor: pointer;
      }
      margin-bottom: 15px;
      height: 35px;
    }
    & input {
      display: none;
    }
  }
}

@media screen and (max-width: 1440px) {
  .settings-app .content .card {
    max-width: 423px;
  }
}

@media screen and (max-width: 1000px) {
  .settings-app .content .card {
    max-width: 366px;
    min-width: 366px;
    width: 100%;
    margin: 0 35px 15px 0;
  }
  .settings-app {
    height: 100%;
    .content {
      display: flex;
      overflow-x: auto;
    }
    ::-webkit-scrollbar {
      width: 13px;
      border-left: 1px solid #c1c1c1;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
    }
    ::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}
