@font-face {
  font-family: "Azo Sans Medium";
  src: local("Azo Sans Medium"), local("AzoSans-Medium"),
    url("~assets/fonts/azoSans/AzoSans-Medium.eot?#iefix") format("embedded-opentype"),
    url("~assets/fonts/azoSans/AzoSans-Medium.woff2") format("woff2"),
    url("~assets/fonts/azoSans/AzoSans-Medium.woff") format("woff"),
    url("~assets/fonts/azoSans/AzoSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Azo Sans Light";
  src: url("~assets/fonts/azoSans/AzoSans-Light.eot");
  src: local("Azo Sans Light"), local("AzoSans-Light"),
    url("~assets/fonts/azoSans/AzoSans-Light.eot?#iefix") format("embedded-opentype"),
    url("~assets/fonts/azoSans/AzoSans-Light.woff2") format("woff2"),
    url("~assets/fonts/azoSans/AzoSans-Light.woff") format("woff"),
    url("~assets/fonts/azoSans/AzoSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Azo Sans Regular";
  src: url("~assets/fonts/azoSans/AzoSans-Regular.eot");
  src: local("Azo Sans Regular"), local("AzoSans-Regular"),
    url("~assets/fonts/azoSans/AzoSans-Regular.eot?#iefix") format("embedded-opentype"),
    url("~assets/fonts/azoSans/AzoSans-Regular.woff2") format("woff2"),
    url("~assets/fonts/azoSans/AzoSans-Regular.woff") format("woff"),
    url("~assets/fonts/azoSans/AzoSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Azo Sans Bold";
  src: url("~assets/fonts/azoSans/AzoSans-Bold.eot");
  src: local("Azo Sans Bold"), local("AzoSans-Bold"),
    url("~assets/fonts/azoSans/AzoSans-Bold.eot?#iefix") format("embedded-opentype"),
    url("~assets/fonts/azoSans/AzoSans-Bold.woff2") format("woff2"),
    url("~assets/fonts/azoSans/AzoSans-Bold.woff") format("woff"),
    url("~assets/fonts/azoSans/AzoSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}


@font-face {
  font-family: "Azo Sans BoldItalic";
  src: url("~assets/fonts/azoSans/AzoSans-BoldItalic.eot");
  src: local("Azo Sans Bold Italic"), local("AzoSans-BoldItalic"),
    url("~assets/fonts/azoSans/AzoSans-BoldItalic.eot?#iefix") format("embedded-opentype"), url("~assets/fonts/azoSans/AzoSans-BoldItalic.woff2") format("woff2"),
    url("~assets/fonts/azoSans/AzoSans-BoldItalic.woff") format("woff"), url("~assets/fonts/azoSans/AzoSans-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Azo Sans Black";
  src: url("~assets/fonts/azoSans/AzoSans-Black.eot");
  src: local("Azo Sans Black"), local("AzoSans-Black"),
  url("~assets/fonts/azoSans/AzoSans-Black.eot?#iefix") format("embedded-opentype"),
  url("~assets/fonts/azoSans/AzoSans-Black.woff2") format("woff2"),
  url("~assets/fonts/azoSans/AzoSans-Black.woff") format("woff"),
  url("~assets/fonts/azoSans/AzoSans-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Azo Sans Tn";
  src: url("~assets/fonts/azoSans/AzoSans-Thin.eot");
  src: local("Azo Sans Thin"), local("AzoSans-Thin"), url("~assets/fonts/azoSans/AzoSans-Thin.eot?#iefix") format("embedded-opentype"),
    url("~assets/fonts/azoSans/AzoSans-Thin.woff2") format("woff2"), url("~assets/fonts/azoSans/AzoSans-Thin.woff") format("woff"),
    url("~assets/fonts/azoSans/AzoSans-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Azo Sans Lt";
  src: url("~assets/fonts/azoSans/AzoSans-LightItalic.eot");
  src: local("Azo Sans Light Italic"), local("AzoSans-LightItalic"),
    url("~assets/fonts/azoSans/AzoSans-LightItalic.eot?#iefix") format("embedded-opentype"), url("~assets/fonts/azoSans/AzoSans-LightItalic.woff2") format("woff2"),
    url("~assets/fonts/azoSans/AzoSans-LightItalic.woff") format("woff"), url("~assets/fonts/azoSans/AzoSans-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Azo Sans Tn";
  src: url("~assets/fonts/azoSans/AzoSans-ThinItalic.eot");
  src: local("Azo Sans Thin Italic"), local("AzoSans-ThinItalic"),
    url("~assets/fonts/azoSans/AzoSans-ThinItalic.eot?#iefix") format("embedded-opentype"), url("~assets/fonts/azoSans/AzoSans-ThinItalic.woff2") format("woff2"),
    url("~assets/fonts/azoSans/AzoSans-ThinItalic.woff") format("woff"), url("~assets/fonts/azoSans/AzoSans-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}
