@import "themes/scss/variables";

.labelContainer {
  max-width: 91px;
  width: 100%;
  position: relative;
}
.labelContainerHovered {
  max-width: 91px;
  width: 100%;
  position: relative;
  height: 73px;
  margin-top: 39px;
}

.btn-label {
  width: 91px;
  height: 30.9px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 6px auto 0px auto;
  filter: drop-shadow(3px 3px 7px $light_grey);
  display: flex;
  justify-content: center;

  span {
    height: 29px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: $light_black;
    svg {
      margin-right: 5px;
    }
  }
}

.toast-success {
  background: $light_green !important;
}
